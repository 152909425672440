import React from 'react';
import styled from '@emotion/styled';

import FAQSection from '@components/faq';

const Wrapper = styled.div({
  marginBottom: 130,
  '@media (max-width: 480px)': {
    marginBottom: 100,
  },
});

export default (props) => (
  <Wrapper>
    <FAQSection {...props} />
  </Wrapper>
);
