import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { COURSE_TYPES } from '@utils/constants';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 170,
  '@media (max-width: 480px)': {
    marginBottom: 70,
  },
});

export const ContentWrapper = styled.div({
  margin: '0 9.5%',
  display: 'grid',
  gridGap: '11%',
  gridTemplateColumns: '39% 44%',
  justifyContent: 'start',
  '@media (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const TextContainer = styled.div({
  marginTop: 86,
  '& h2': {
    lineHeight: '44px',
    marginBottom: 11,
  },
  '@media (max-width: 480px)': {
    marginTop: 40,
    textAlign: 'center',
    '& h2': {
      lineHeight: '33px',
      marginBottom: 11,
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
}));

export const Card = styled.div({
  padding: '32px 40px 40px 40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 5,
  textAlign: 'center',
  '& h4': {
    fontWeight: 'bold',
  },
  '& img': {
    opacity: 0.8,
  },
  '&:hover': {
    '& img': {
      opacity: 1,
    },
  },
  '@media (max-width: 480px)': {
    width: 256,
    padding: '15px 15px 25px',
    '& a': {
      width: 130,
      height: 32,
      lineHeight: '28px',
      fontSize: 12,
    },
    '& a:hover': {
      lineHeight: '32px',
    },
  },
},
({ theme }) => ({
  backgroundColor: theme.colors.background,
  boxShadow: theme.shadows.pathItem,
  '&:hover': {
    boxShadow: theme.shadows.pathItemHovered,
  },
  '& h4': {
    color: theme.colors.textHighlighted,
  },
}));

export const StyledImage = styled.img({
  marginBottom: 10,
  '@media (max-width: 480px)': {
    height: 50,
  },
},
({ type }) => ({
  height: type === COURSE_TYPES.JUNIOR ? 70 : 100,
  marginTop: type === COURSE_TYPES.JUNIOR ? 30 : 0,
  '@media (max-width: 480px)': {
    height: type === COURSE_TYPES.JUNIOR ? 30 : 50,
    marginTop: type === COURSE_TYPES.JUNIOR ? 20 : 0,
  },
}));

export const OptionsContainer = styled.div({
  marginBottom: 22,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 480px)': {
    marginBottom: 15,
    '& span.solutions-trial-price': {
      fontSize: 29,
    },
    '& span.solutions-trial-price_text': {
      fontSize: 18,
    },
  },
});

export const StyledLink = styled(Link)({
  fontSize: 21,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  '@media (max-width: 480px)': {
    fontSize: 15,
    textDecoration: 'underline',
  },
},
({ theme }) => ({
  color: theme.colors.textGray,
}));
