import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, ContentWrapper } from './styles';

const OverviewSection = ({ content }) => (
  <Wrapper>
    <ContentWrapper>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ContentWrapper>
  </Wrapper>
);

OverviewSection.propTypes = {
  content: PropTypes.string,
};

export default OverviewSection;
