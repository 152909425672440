import styled from '@emotion/styled';
import { COURSE_TYPES } from '@utils/constants';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 50,
  paddingLeft: '10%',
  paddingTop: 150,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: 100,
  overflow: 'hidden',
  '@media (max-width: 480px)': {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 0,
    marginBottom: 76,
  },
},
({ theme }) => ({
  backgroundColor: theme.colors.backgroundMedium,
  '@media (max-width: 480px)': {
    paddingTop: theme.sizes.headerMobileHeight,
    marginTop: -theme.sizes.headerMobileHeight,
    boxShadow: theme.shadows.heroMobile,
  },
}));

export const ContentWrapper = styled.div({
  '& h1': {
    display: 'inline-block',
    fontSize: 40,
    fontWeight: 'normal',
    marginBottom: 12,
  },
  '& p': {
    fontSize: 21,
    lineHeight: '25px',
    marginBottom: 23,
  },
  '& li': {
    fontSize: 21,
    listStyle: 'none',
    position: 'relative',
    paddingLeft: 30,
    marginBottom: 6,
    '&:before': {
      content: "''",
      display: 'block',
      width: 11,
      height: 11,
      borderRadius: '50%',
      position: 'absolute',
      top: 9,
      left: 10,
    },
  },
  '@media (max-width: 480px)': {
    order: 2,
    padding: '0 10%',
    textAlign: 'center',
    '& h1': {
      fontSize: 36,
      lineHeight: '38px',
      marginBottom: 18,
    },
    '& p': {
      fontSize: 15,
      lineHeight: '22px',
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 56,
    },
    '& li': {
      fontSize: 12,
      paddingLeft: 0,
      marginBottom: 0,
      '&:before': {
        display: 'none',
      },
    },
    '& li:first-of-type': {
      marginBottom: 10,
    },
  },
},
({ theme, type }) => ({
  '& h1': {
    color: theme.colors.textHighlighted,
    '@media (max-width: 480px)': {
      width: type === COURSE_TYPES.JUNIOR ? '50%' : '100%',
      letterSpacing: type === COURSE_TYPES.NOVOTALK ? '-1.6px' : 0,
    },
  },
  '& p': {
    color: theme.colors.textGray,
  },
  '& li': {
    color: theme.colors.textGray,
    '&:before': {
      backgroundColor: theme.colors.textHighlighted,
    },
  },
}));

export const ButtonsWrapper = styled.div({
  marginTop: 40,
  display: 'flex',
  '@media (max-width: 480px)': {
    marginTop: 0,
    marginBottom: 20,
    justifyContent: 'center',
    '& button': {
      width: 118,
      height: 40,
      margin: 0,
      fontSize: 14,
    },
  },
});

export const StyledImage = styled.img({
  marginBottom: 110,
  '@media (max-width: 480px)': {
    display: 'none',
  },
},
({ type }) => ({
  width: type === COURSE_TYPES.JUNIOR ? '110%' : '100%',
  marginTop: type === COURSE_TYPES.ACCELERATOR ? 25 : -45,
  marginRight: type === COURSE_TYPES.JUNIOR ? '-10%' : 0,
}));

export const StyledMobileImage = styled.img({
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'block',
    marginTop: 22,
    marginBottom: 22,
  },
},
({ type }) => ({
  '@media (max-width: 480px)': {
    width: type === COURSE_TYPES.NOVOTALK ? '80%' : '60%',
    marginTop: type === COURSE_TYPES.NOVOTALK ? 37 : 22,
  },
}));

export const StyledIcon = styled.img({
  height: 50,
  display: 'inline-block',
  marginRight: 12,
  '@media (max-width: 480px)': {
    display: 'none',
  },
},
({ type }) => ({
  height: type === COURSE_TYPES.JUNIOR ? 30 : 50,
  marginBottom: type === COURSE_TYPES.JUNIOR ? 0 : -5,
}));
