import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 90,
  '@media (max-width: 480px)': {
    marginBottom: 107,
  },
});

export const ContentWrapper = styled.div({
  padding: '0 9.5%',
  '& h2': {
    marginBottom: 66,
    textAlign: 'center',
  },
  '@media (max-width: 480px)': {
    marginBottom: 38,
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
}));

export const ListWrapper = styled.ul({
  listStyle: 'none',
});

export const ListItem = styled.li({
  marginBottom: 28,
  '@media (max-width: 480px)': {
    marginBottom: 21,
  },
},
({ theme }) => ({
  boxShadow: theme.shadows.syllabusListItem,
}));

export const ListHeader = styled.div({
  minHeight: 74,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px 0 75px',
  '@media (max-width: 480px)': {
    minHeight: 41,
    padding: 10,
    '& h4 span': {
      fontSize: 15,
    },
    '& span.solutions-course-time_count': {
      fontSize: 12,
      marginLeft: 20,
    },
    '& span.solutions-course-lectures_count': {
      display: 'none',
    },
  },
});
