import React from 'react';
import PropTypes from 'prop-types';

import Button from '@components/button';
import BackgroundRefresherImg from '@assets/images/hero_refresher.svg';
import BackgroundChronicImg from '@assets/images/hero_chronic.svg';
import BackgroundChronicMobileImg from '@assets/images/hero_chronic_mobile.svg';
import BackgroundJuniorImg from '@assets/images/hero_junior.svg';
import IconRefresherImg from '@assets/images/paths_refresher_home.svg';
import IconChronicImg from '@assets/images/paths_chronic_home.svg';
import IconJuniorImg from '@assets/images/paths_junior_home.svg';
import { COURSE_TYPES } from '@utils/constants';
import {
  Wrapper, ContentWrapper, ButtonsWrapper, StyledImage, StyledIcon, StyledMobileImage,
} from './styles';

const HeroSection = ({ content, type }) => {
  let icon;
  let image;
  let imageMobile;
  switch (type) {
    case COURSE_TYPES.ACCELERATOR:
      icon = IconRefresherImg;
      image = BackgroundRefresherImg;
      break;
    case COURSE_TYPES.NOVOTALK:
      icon = IconChronicImg;
      image = BackgroundChronicImg;
      imageMobile = BackgroundChronicMobileImg;
      break;
    default:
      icon = IconJuniorImg;
      image = BackgroundJuniorImg;
  }

  return (
    <Wrapper>
      <StyledMobileImage src={imageMobile || image} alt="" type={type} />
      <ContentWrapper type={type}>
        <StyledIcon src={icon} alt="" type={type} />
        <span dangerouslySetInnerHTML={{ __html: content }} />
        <ButtonsWrapper>
          <Button
            width={120}
            height={32}
            href="/sign-up/index.html"
          >
            Sign up
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
      <StyledImage src={image} alt="" type={type} />
    </Wrapper>
  );
};

HeroSection.propTypes = {
  content: PropTypes.string,
  type: PropTypes.string,
};

export default HeroSection;
