import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Text, { TEXT_COLOR } from '../../../text';
import {
  Wrapper, ContentWrapper, ListWrapper, ListItem, ListHeader,
} from './styles';

class CourseSection extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  renderList = () => {
    const { courseData } = this.props;
    return (
      <ListWrapper>
        <ListItem>
          {courseData.map(({ value: { skillName, lecturesCount, totalTime } }, index) => (
            <ListHeader key={index}>
              <h4>
                <Text fontSize={21} color={TEXT_COLOR.DARK_GRAY}>
                  {skillName}
                </Text>
              </h4>
              <span>
                <Text fontSize={21} color={TEXT_COLOR.DARK_GRAY} margin="0 25px 0 0" className="solutions-course-lectures_count">
                  {`${lecturesCount} lessons`}
                </Text>
                <Text fontSize={21} color={TEXT_COLOR.DARK_GRAY} className="solutions-course-time_count">
                  {totalTime}
                </Text>
              </span>
            </ListHeader>
          ))}
        </ListItem>
      </ListWrapper>
    );
  }

  render() {
    const { content } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {this.renderList()}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

CourseSection.propTypes = {
  content: PropTypes.string,
  courseData: PropTypes.array,
};

export default CourseSection;
