import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import HeroSection from '@components/pages/programs/hero';
import OverviewSection from '@components/pages/programs/overview';
import CourseSection from '@components/pages/programs/course';
import FAQSection from '@components/pages/programs/faq';
import StartTrialSection from '@components/pages/programs/trial';
import GetStarted from '@components/getStarted';

class ProgramsLayout extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data.allCockpitprograms.edges[0].node[section].childMarkdownRemark.html;
  }

  render() {
    const { data, pageContext } = this.props;
    const { syllabus, payment } = data.allCockpitcourses.edges[0].node.entry;
    return (
      <MainLayout paintedMobileHeader>
        {/* TODO: add keywords */}
        <SEO title="Programs" keywords={[]} />
        <HeroSection content={this.getSectionContent('hero')} type={pageContext.type} />
        <OverviewSection content={this.getSectionContent('overview')} />
        <CourseSection content={this.getSectionContent('course')} courseData={syllabus} />
        <GetStarted margin={170} type={pageContext.type} />
        <FAQSection content={this.getSectionContent('faq')} />
        <StartTrialSection content={this.getSectionContent('trial')} paymentData={payment} type={pageContext.type} />
      </MainLayout>
    );
  }
}

ProgramsLayout.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default ProgramsLayout;

export const query = graphql`
  query ProgramsQuery($type: String!) {
    allCockpitprograms(filter: {
      entry: {
        page: {
          eq: $type,
        }
      }
    }) {
      edges {
        node {
          hero: childCockpitprogramsHeroTextNode {
            childMarkdownRemark {
              html
            }
          }
          overview: childCockpitprogramsOverviewTextNode {
            childMarkdownRemark {
              html
            }
          }
          course: childCockpitprogramsCourseTextNode {
            childMarkdownRemark {
              html
            }
          }
          faq: childCockpitprogramsFaqTextNode {
            childMarkdownRemark {
              html
            }
          }
          trial: childCockpitprogramsTrialTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allCockpitcourses(filter: {
      entry: {
        courseType: {
          eq: $type,
        }
      }
    }) {
      edges {
        node {
          entry {
            syllabus {
              value {
                skillName
                lecturesCount
                totalTime
              }
            }
            payment {
              monthly
              annually
            }
          }
        }
      }
    }
  }
`;
