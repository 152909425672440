import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 110,
  padding: '0 9.5%',
});

export const ContentWrapper = styled.div({
  '& h2': {
    textAlign: 'center',
    marginBottom: 82,
  },
  '& h4': {
    fontWeight: 'bold',
  },
  '& p': {
    marginTop: 15,
  },
  '& ul': {
    marginTop: 40,
  },
  '& li': {
    fontSize: 18,
    listStyle: 'none',
    position: 'relative',
    paddingLeft: 40,
    marginBottom: 18,
    '&:last-of-type': {
      marginBottom: 48,
    },
    '&:before': {
      content: "''",
      display: 'block',
      width: 18,
      height: 18,
      borderRadius: '50%',
      position: 'absolute',
      top: 3,
      left: 0,
    },
  },
  '@media (max-width: 480px)': {
    '& h2': {
      marginBottom: 62,
    },
    '& h4': {
      textAlign: 'center',
      lineHeight: '19px',
    },
    '& p': {
      marginTop: 20,
    },
    '& ul': {
      marginTop: 22,
    },
    '& li': {
      fontSize: 15,
      paddingLeft: 20,
      marginBottom: 22,
      '&:last-of-type': {
        marginBottom: 96,
      },
      '&:before': {
        width: 10,
        height: 10,
        top: 5,
      },
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h4, & p': {
    color: theme.colors.textGray,
  },
  '& li': {
    color: theme.colors.textGray,
    '&:before': {
      backgroundColor: theme.colors.buttonPrimary,
    },
  },
}));
