import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@components/button';
import TriggerButton from '@components/triggerButton';
import RefresherImg from '@assets/images/paths_refresher_home.svg';
import ChronicImg from '@assets/images/paths_chronic_home.svg';
import JuniorImg from '@assets/images/paths_junior_home.svg';
import Text, { TEXT_COLOR } from '@components/text';
import { COURSE_TYPES } from '@utils/constants';
import {
  Wrapper, ContentWrapper, TextContainer, Card, OptionsContainer, StyledImage, StyledLink,
} from './styles';

class StartTrialSection extends Component {
  constructor() {
    super();
    this.state = {
      isAnnuallyChecked: true,
    };
  }

  handleTrigger = () => {
    this.setState((prevState) => (
      { isAnnuallyChecked: !prevState.isAnnuallyChecked }
    ));
  }

  renderCard = (header) => {
    const { isAnnuallyChecked } = this.state;
    const { paymentData, type } = this.props;
    let image;
    switch (type) {
      case COURSE_TYPES.ACCELERATOR:
        image = RefresherImg;
        break;
      case COURSE_TYPES.NOVOTALK:
        image = ChronicImg;
        break;
      default:
        image = JuniorImg;
    }
    return (
      <Card>
        <StyledImage src={image} alt="" type={type} />
        <div dangerouslySetInnerHTML={{ __html: header }} />
        <OptionsContainer>
          <TriggerButton
            triggerState={isAnnuallyChecked}
            handleTrigger={this.handleTrigger}
            margin="24px 0 20px 0"
          />
          <Text fontSize={40} color={TEXT_COLOR.HIGHLIGHTED} className="solutions-trial-price">
            {`${paymentData[isAnnuallyChecked ? 'annually' : 'monthly']}$`}
          </Text>
          <Text fontSize={21} color={TEXT_COLOR.HIGHLIGHTED} className="solutions-trial-price_text">
            per week
          </Text>
        </OptionsContainer>
        <Button
          width={268}
          height={53}
          outline
          href="/sign-up/index.html"
        >
          Sign up
        </Button>
      </Card>
    );
  }

  render() {
    const { content } = this.props;
    const splittedContent = content.split('<hr>');
    return (
      <Wrapper>
        <ContentWrapper>
          {this.renderCard(splittedContent[1])}
          <TextContainer>
            {/* <div dangerouslySetInnerHTML={{ __html: splittedContent[0] }} />
            <StyledLink to="/pricing">See pricing options</StyledLink> */}
          </TextContainer>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

StartTrialSection.propTypes = {
  content: PropTypes.string,
  paymentData: PropTypes.object,
  type: PropTypes.string,
};

export default StartTrialSection;
